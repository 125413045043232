<template>
  <power-bi
    v-if="code"
    :code="code"
  />
</template>

<script>
import PowerBi from '../components/PowerBi.vue';

import DashboardService from '@/service/dashboard/get-by-params';

export default {
    components: {
        PowerBi,
    },

    data() {
        return {
            code: null,
        };
    },

    async mounted() {
        this.callLoading(true);
        const response = await DashboardService.get({
            email: this.$store.getters['user/user'].data.email,
            dashboard: 'budget',
        });
        this.code = response?.data?.code || null;
        this.callLoading(false);
    },
};
</script>
