import Thoth from '@/service/base/thoth';

class DashboardService extends Thoth {
    async get(
        {
            email,
            dashboard,
        },
    ) {
        return this.fetch({
            url: '/code-dashboard',
            params: {
                email,
                dashboard,
            },
        });
    }
}

export default new DashboardService();
